import React, { Component } from "react";
import { Fragment } from "react";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";

import { CatalogLoader, ProductListLoader } from "../commoncomponent";
import CataProductLoader from "../commoncomponent/CatProductLoader";

function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return (
	  <div
		className={"next slick-arrow fa fa-angle-right"}
		style={{ ...style ,borderRadius: "25px", padding: "12px 20px", backgroundColor:"#fff", fontSize: "22px", boxShadow: "rgba(0,0,0,.24) 0px 3px 8px" }}
		onClick={onClick}
	  />
	);
  }
  
  function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return (
	  <div
		className={"prev slick-arrow fa fa-angle-left"}
		style={{ ...style ,borderRadius: "25px", padding: "12px 20px", backgroundColor:"#fff", fontSize: "22px", boxShadow: "rgba(0,0,0,.24) 0px 3px 8px" }}
		onClick={onClick}
	  />
	);
  }

export default class CenterMode extends Component {
  constructor(props) {
    
    super(props);
  }
  render() {
    
    const { products, cat } = this.props;
   if(products.items.length>0)
{
 products.items.sort((a,b)=> (parseInt(a.sortby) > parseInt(b.sortby) ? 1 : -1))
}
   
		const settings = {
			centerMode: true,
			centerPadding: '0px',
			slidesToShow: 3,
			nextArrow: <SampleNextArrow />,
      		prevArrow: <SamplePrevArrow />,
			responsive: [{
				breakpoint: 1169,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 969,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1
				}
			}
		]
    };
    const onefailed = 0;
		return (
      <Fragment>
        <div class="featured-area featured-one pt-40" style={{ width: "100%" }}>
          <div class="container">
            <div class="featured-slider single-products text-center">
              {products.loading ? (
                <center>
                  <CataProductLoader />
                </center>
              ) : (
                <React.Fragment>
                  <Slider {...settings}>
                    {products.items.length > 0
                      ? products.items.map((product) => {
                          return (
                            <div class="single-slide">
                              <div class="padding30">
                                <div class="product-item">
                                  <div class="pro-img">
                                    <Link
                                      to={
                                        process.env.PUBLIC_URL +
                                        "/product/" +
                                        product.url_key
                                      }
                                      onClick={(event) =>
                                        localStorage.setItem(
                                          "pro" + product.url_key,
                                          product.name
                                        )
                                      }
                                    >
                                      <img
                                        src={
                                          product.images != undefined &&
                                          product.images.length > 0
                                            ? product.images[0].large_image_url
                                            : ""
                                        }
                                        alt=""
                                      />
                                    </Link>
                                  </div>
                                  <div class="actions-btn">
                                    <ul class="clearfix">
                                      <li>
                                        <Link
                                          to={
                                            process.env.PUBLIC_URL +
                                            "/product/" +
                                            product.url_key
                                          }
                                          onClick={(event) =>
                                            localStorage.setItem(
                                              "pro" + product.url_key,
                                              product.name
                                            )
                                          }
                                        >
                                          {product.name}
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="product-title">
                                    <Link
                                      to={
                                        process.env.PUBLIC_URL +
                                        "/product/" +
                                        product.url_key
                                      }
                                      onClick={(event) =>
                                        localStorage.setItem(
                                          "pro" + product.url_key,
                                          product.name
                                        )
                                      }
                                    >
                                      <h5
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            product.short_description.replace(
                                              /<[^>]+>/g,
                                              ""
                                            ),
                                        }}
                                      ></h5>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </Slider>
                  {products.items.length < 1 ? (
                    <div className="no_record_com" id={cat}>
                      {" "}
                      No Product available!
                    </div>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              )}
            </div>
            <div class="check-coll">
              {!products.loading ? (
                <Link
                  class="check-coll-btn text-uppercase"
                  to={process.env.PUBLIC_URL + "/catalog/" + cat.slug}
                >
                  <i class="fa fa-bicycle"></i>Check All {cat.name} Cycles
                </Link>  
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
	}
}
