import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import { frontUrl, storageUrl } from "../../common";
import FooterCopyright from "../../components/footer/FooterCopyright";
import FooterNewsletter from "../../components/footer/FooterNewsletter";

const FooterOne = ({
  backgroundColorClass,
  spaceTopClass,
  spaceBottomClass,
  spaceLeftClass,
  spaceRightClass,
  containerClass,
  extraFooterClass,
  sideMenu,
}) => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);

  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <Fragment>
      <footer
        className={`footer-area home1footer ${backgroundColorClass ? backgroundColorClass : ""
          } ${spaceTopClass ? spaceTopClass : ""} ${spaceBottomClass ? spaceBottomClass : ""
          } ${extraFooterClass ? extraFooterClass : ""} ${spaceLeftClass ? spaceLeftClass : ""
          } ${spaceRightClass ? spaceRightClass : ""}`}
      >
        <div className={`${containerClass ? containerClass : "container"}`}>
          <div className="row">
            <div
              className={`${sideMenu
                ? "col-lg-4 col-md-6 col-sm-4"
                : "col-lg-4  col-md-6 col-sm-4"
                }`}
            >
              {/* <FooterCopyright
                          footerLogo="/assets/img/logo/logo3.png"
                          spaceBottomClass="mb-30"
                        /> */}{" "}
              <h3 class="f-title f_600 t_color f_size_16"> SK Bikes Pvt Ltd. </h3>{" "}
              <h5 class="pt-3">
                {" "}
                <b> Address: </b> D 233 - 235, Phase VII, Focal Point, Ludhiana
              141010, Punjab, India. <br />
                {/* <b> Mobile: </b> +91 7986529780 <br /> */}
                <b> Phone: </b> +91-161-4353000 <br />
                <b> email: </b>{" "}
                <a href="mailto:sales@skbikes.com ">sales@skbikes.com</a> |
              <a href="mailto:info@skbikes.com ">  info@skbikes.com</a>
              </h5>{" "}
            </div>{" "}
            <div
              className={`${sideMenu
                ? "col-lg-2 col-md-6 col-sm-4"
                : "col-lg-2 col-md-6 col-sm-4"
                }`}
            >
              {" "}
              {/* <div className="footer-widget mb-30 ml-30">
                                    <div className="footer-title">
                                    <h3 class="f-title f_600 t_color f_size_18">SK Bikes Pvt Ltd.</h3>
                                    </div>
                                    <div className="footer-list">
                                      <ul>
                                        <li>
                                          <Link to={process.env.PUBLIC_URL + "/page/about-us"}>
                                            About us
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to={process.env.PUBLIC_URL + "/page/store-location"}>
                                            Store location
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to={process.env.PUBLIC_URL + "/contact"}>
                                            Contact
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to={process.env.PUBLIC_URL + "/page/order-tracking"}>
                                            Orders tracking
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div> */}{" "}
              <h3 class="f-title f_600 t_color f_size_16"> Company Links </h3>{" "}
              <ul class="list-unstyled f_list pt-3 com_links">
                <li>
                  {" "}
                  <a href={process.env.PUBLIC_URL + "/page/about-us"}>
                    {" "}
                  About us{" "}
                  </a>
                </li>{" "}
                <br />
                <li>
                  {" "}
                  <a href={process.env.PUBLIC_URL + "/catalog/root?featured=1"}>
                    {" "}
                  New Launches{" "}
                  </a>
                </li>{" "}
                <br />
                <li>
                  {" "}
                  <a href={process.env.PUBLIC_URL + "/contact "}> Contact Us </a>
                </li>{" "}
                <br />
              </ul>{" "}
            </div>{" "}
            <div
              className={`${sideMenu
                ? "col-lg-3 col-md-6 col-sm-4"
                : "col-lg-3 col-md-6 col-sm-4"
                }`}
            >
              {" "}
              {/* <div
                                                                      className={`${
                                                                        sideMenu
                                                                          ? "footer-widget mb-30 ml-95"
                                                                          : "footer-widget mb-30 ml-50"
                                                                      }`}
                                                                    >
                                                                      <div className="footer-title">
                                                                        <h3>USEFUL LINKS</h3>
                                                                      </div>
                                                                      <div className="footer-list">
                                                                        <ul>
                                                                          <li>
                                                                            <Link
                                                                              to={process.env.PUBLIC_URL + "/page/terms-and-conditions"}
                                                                            >
                                                                              {" Term & Condition"}
                                                                            </Link>
                                                                          </li>
                                                                          <li>
                                                                            <Link
                                                                              to={process.env.PUBLIC_URL + "/page/copyright-policy"}
                                                                            >
                                                                              Copyright Policy
                                                                            </Link>
                                                                          </li>
                                            
                                                                          <li>
                                                                            <Link to={process.env.PUBLIC_URL + "/page/privacy-policy"}>
                                                                              {"Privacy policy"}
                                                                            </Link>
                                                                          </li>
                                                                          <li>
                                                                            <Link
                                                                              to={process.env.PUBLIC_URL + "/page/cancellation-returns"}
                                                                            >
                                                                              {"Cancellation & Returns"}
                                                                            </Link>
                                                                          </li>
                                                                        </ul>
                                                                      </div>
                                                                    </div> */}
              <h3 class="f-title f_600 t_color f_size_16"> Bicycle Series </h3>{" "}
              <ul class="list-unstyled f_list b_cat pt-3">
                <li>
                  {" "}
                  <a
                    href={
                      process.env.PUBLIC_URL + "/catalog/fat-bike-mtb-multi-speed"
                    }
                  >
                    {" "}
                  FAT BIKE & MTB MULTI SPEED{" "}
                  </a>
                </li>{" "}
                <br />
                <li>
                  {" "}
                  <a href={process.env.PUBLIC_URL + "/catalog/mtbatb"}>
                    {" "}
                  MTB / ATB{" "}
                  </a>
                </li>{" "}
                <br />
                <li>
                  {" "}
                  <a href={process.env.PUBLIC_URL + "/catalog/city"}>
                    {" "}
                  CITY BIKE{" "}
                  </a>
                </li>{" "}
                <br />
                <li>
                  {" "}
                  <a href={process.env.PUBLIC_URL + "/catalog/ladies"}>
                    {" "}
                  LADIES{" "}
                  </a>
                </li>{" "}
                <br />
                <li>
                  {" "}
                  <a href={process.env.PUBLIC_URL + "/catalog/kids"}> KIDS </a>
                </li>{" "}
                <br />
              </ul>{" "}
            </div>
            <div
              className={`${sideMenu
                ? "col-lg-3 col-md-6 col-sm-4"
                : "col-lg-3 col-md-6 col-sm-6"
                }`}
            >
              {" "}
              {/* <div
                                                                                                      className={`${
                                                                                                        sideMenu
                                                                                                          ? "footer-widget mb-30 ml-145"
                                                                                                          : "footer-widget mb-30 ml-75"
                                                                                                      }`}
                                                                                                    >
                                                                                                      <div className="footer-title">
                                                                                                        <h3>FOLLOW US</h3>
                                                                                                      </div>
                                                                                                      <div className="footer-list">
                                                                                                        <ul>
                                                                                                          <li>
                                                                                                            <a
                                                                                                              href="//www.facebook.com"
                                                                                                              target="_blank"
                                                                                                              rel="noopener noreferrer"
                                                                                                            >
                                                                                                              Facebook
                                                                                                            </a>
                                                                                                          </li>
                                                                            
                                                                                                          <li>
                                                                                                            <a
                                                                                                              href="//www.instagram.com"
                                                                                                              target="_blank"
                                                                                                              rel="noopener noreferrer"
                                                                                                            >
                                                                                                              Instagram
                                                                                                            </a>
                                                                                                          </li>
                                                                                                        </ul>
                                                                                                      </div>
                                                                                                    </div> */}{" "}
              <h3 class="f-title f_600 t_color f_size_16"> Get In Touch </h3>{" "}
              <div class="f_social_icon">
                <a href="https://www.facebook.com/gangcyclesofficial/" target="_blank" class="fa fa-facebook">
                  {" "}
                </a>{" "}
                <a href="https://www.instagram.com/gangcycles/" target="_blank" class="fa fa-instagram">
                  {" "}
                </a>{" "}
                <a href="https://twitter.com/CyclesGang" target="_blank" class="fa fa-twitter">
                  {" "}
                </a>{" "}
                <a href="https://www.linkedin.com/in/gangcycles/" target="_blank" class="fa fa-linkedin">
                  {" "}
                </a>{" "}
                <a href="https://www.youtube.com/channel/UCa2yzeIIOdpJ639-hn2NgBA" target="_blank" class="fa fa-youtube">
                  {" "}
                </a>{" "}
              </div>{" "}
            </div>{" "}
            {/* <div
                                                                            className={`${
                                                                              sideMenu ? "col-xl-3 col-sm-8" : "col-lg-4 col-sm-6"
                                                                            }`}
                                                                          > */}
            {/* <FooterNewsletter
                                                                              spaceBottomClass="mb-30"
                                                                              spaceLeftClass="ml-70"
                                                                              sideMenu={sideMenu}
                                                                            /> */}{" "}
            {/* </div> */}
          </div>{" "}
        </div>
        <div
          class="footer_bg"
          style={{
            backgroundImage: `url('${storageUrl}/configuration/footer_back.PNG')`,
            position: "absolute",
            bottom: "0",
            width: "100%",
            height: "266px",
          }}
        >
          <div class="footer_bg_one"></div>{" "}
          <div
            class="footer_bg_two"
            style={{
              backgroundImage: `url('${storageUrl}/configuration/cyclist.gif')`,
            }}
          >
            {" "}
          </div>{" "}
        </div>
        <button
          className={`scroll-top ${scroll > top ? "show" : ""}`}
          onClick={() => scrollToTop()}
        >
          <i className="fa fa-angle-double-up"> </i>{" "}
        </button>{" "}
      </footer>
      <div className="footer-cp footer-cp-bg">
        <div className="container p-4">
          <div className="row">
            <div className="col-lg-6 col-12">
              <p className="font-size-16 text-left">© Gang 2021 | All Rights Reserved</p>
            </div>
            <div className="col-lg-6 col-12">
              <p className="font-size-16 text-right">Designed &amp; Developed by <a href="https://www.cyberframe.in/" target="_blank">Cyberframe</a></p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

FooterOne.propTypes = {
  backgroundColorClass: PropTypes.string,
  containerClass: PropTypes.string,
  extraFooterClass: PropTypes.string,
  sideMenu: PropTypes.bool,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string,
};

export default FooterOne;
