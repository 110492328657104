import React from 'react'
import ContentLoader from 'react-content-loader'

const ProductDetails = props => {
  return (
    <ContentLoader viewBox="0 0 1200 591" height={591} width={1200} {...props}>
      <rect x="64" y="70" rx="0" ry="0" width="460" height="300" />
      <rect x="64" y="392" rx="0" ry="0" width="100" height="100" />
      <rect x="184" y="392" rx="0" ry="0" width="100" height="100" />
      <rect x="304" y="392" rx="0" ry="0" width="100" height="100" />
      <rect x="424" y="392" rx="0" ry="0" width="100" height="100" />
      
      <rect x="590" y="70" rx="0" ry="0" width="470" height="25" />
      <rect x="590" y="120" rx="0" ry="0" width="100" height="30" />
      
      <circle cx="595" cy="205" r="10" />
      <rect x="610" y="195" rx="5" ry="10" width="220" height="20" />
      <circle cx="595" cy="240" r="10" />
      <rect x="610" y="230" rx="5" ry="10" width="220" height="20" />

      <rect x="590" y="330" rx="5" ry="10" width="470" height="2" />

      <rect x="590" y="350" rx="0" ry="0" width="90" height="50" />
      <rect x="690" y="350" rx="0" ry="0" width="180" height="50" />
      <rect x="880" y="350" rx="0" ry="0" width="50" height="50" />
      <rect x="940" y="350" rx="0" ry="0" width="50" height="50" />
      

      {/* <rect x="487" y="244" rx="5" ry="5" width="220" height="10" />
      <rect x="229" y="300" rx="0" ry="0" width="0" height="0" />
      <rect x="111" y="340" rx="0" ry="0" width="0" height="0" />
      <rect x="121" y="342" rx="0" ry="0" width="0" height="0" />
      <rect x="194" y="329" rx="0" ry="0" width="0" height="0" />
      <rect x="192" y="323" rx="0" ry="0" width="0" height="0" />
      <rect x="185" y="323" rx="0" ry="0" width="0" height="0" />
      <rect x="470" y="58" rx="0" ry="0" width="300" height="6" />
      <rect x="470" y="68" rx="0" ry="0" width="300" height="6" />
      <rect x="470" y="78" rx="0" ry="0" width="300" height="6" />
      <rect x="798" y="135" rx="0" ry="0" width="0" height="0" />
      <rect x="731" y="132" rx="0" ry="0" width="0" height="0" />
      <rect x="560" y="99" rx="0" ry="0" width="70" height="30" /> */}
    </ContentLoader>
  )
}

ProductDetails.metadata = {
  name: 'Mohd Arif Uddin', // My name
  github: 'Arif-un', // Github username
  description: 'E-Commerce / Online Shop Product Details page', // Little tagline
  filename: 'ProductDetails', // filename of your loader
}

export default ProductDetails