import PropTypes from "prop-types";
import React,{ Fragment, useEffect } from "react";
import { multilanguage } from "redux-multilanguage";
import { getBrands } from "../../redux/product"
import { connect } from "react-redux";
import { setCurrency } from "../../redux/actions/currencyActions";
import LanguageCurrencyChanger from "./sub-components/LanguageCurrencyChanger";
import {getCategories,getChildCategories } from "../../redux/category"

const HeaderTop = ({
  currency,
  setCurrency,
  currentLanguageCode,
  dispatch,
  borderStyle,
  getCategories,
  getChildCategories
}) => {

  useEffect( () => {
  
    getCategories();   
    
   
  
  }, [] );
  return (
    <div
      className={`header-top-wap ${
        borderStyle === "fluid-border" ? "border-bottom" : ""
      }`}
    >
      <LanguageCurrencyChanger
        currency={currency}
        setCurrency={setCurrency}
        currentLanguageCode={currentLanguageCode}
        dispatch={dispatch}
      />
      {/* <div className="col-xl-2 col-lg-2 col-md-6 col-8"> */}
        {/* Icon group */}
        {/* <IconGroup />
      </div> */}
      <div className="header-offer">
        <p>
          Free delivery on order over{" "}
          <span>
            {currency.currencySymbol + (200 * currency.currencyRate).toFixed(2)}
          </span>
        </p>
      </div>
    </div>
  );
};

HeaderTop.propTypes = {
  borderStyle: PropTypes.string,
  setCurrency: PropTypes.func,
  currency: PropTypes.object,
  currentLanguageCode: PropTypes.string,
  dispatch: PropTypes.func
};

const mapStateToProps = state => {
  return {
    currency: state.currencyData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCurrency: currencyName => {
      dispatch(setCurrency(currencyName));
    },
    getCategories:() => dispatch(getCategories())  ,
    getChildCategories:(id,cat)=>dispatch(getChildCategories(id,cat)),
    getBrands
   
  
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(multilanguage(HeaderTop));
