export const PRODUCT_LIST = "PRODUCT_LIST";
export const PRODUCT_LIST_FAILED = "PRODUCT_LIST_FAILED";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";

/*** New Product Arrival  */
export const NEW_PRODUCT_LIST = "NEW_PRODUCT_LIST";
export const NEW_PRODUCT_LIST_FAILED = "NEW_PRODUCT_LIST_FAILED";
export const NEW_PRODUCT_LIST_SUCCESS = "NEW_PRODUCT_LIST_SUCCESS";

/*** Best Seller  *****/
export const BEST_SELLER_LIST = "BEST_SELLER_LIST";
export const BEST_SELLER_LIST_FAILED = "BEST_SELLER_LIST_FAILED";
export const BEST_SELLER_LIST_SUCCESS = "BEST_SELLER_LIST_SUCCESS";


/*** SALE ITEM  *****/
export const SALE_ITEM_LIST = "SALE_ITEM_LIST";
export const SALE_ITEM_LIST_FAILED = "SALE_ITEM_LIST_FAILED";
export const SALE_ITEM_LIST_SUCCESS = "SALE_ITEM_LIST_SUCCESS";

/***  Product Detail  */
export const PRODUCT_DETAIL = "PRODUCT_DETAIL";
export const PRODUCT_DETAIL_FAILED = "PRODUCT_DETAIL_FAILED";
export const PRODUCT_DETAIL_SUCCESS = "PRODUCT_DETAIL_SUCCESS";

/***  Product Detail  */
export const PRODUCT_ADDITIONALS = "PRODUCT_ADDITIONALS";
export const PRODUCT_ADDITIONALS_FAILED = "PRODUCT_ADDITIONALS_FAILED";
export const PRODUCT_ADDITIONALS_SUCCESS = "PRODUCT_ADDITIONALS_SUCCESS";

/***  Product Variations  */
export const PRODUCT_VARIATION_DETAIL = " PRODUCT_VARIATION_DETAIL";
export const PRODUCT_VARIATION_DETAIL_FAILED = " PRODUCT_VARIATION_DETAIL_FAILED";
export const PRODUCT_VARIATION_DETAIL_SUCCESS = " PRODUCT_VARIATION_DETAIL_SUCCESS";

/***  Product Filtes  */
export const PRODUCT_FILTERS = "PRODUCT_FILTERS";
export const PRODUCT_FILTERS_FAILED = "PRODUCT_FILTERS_FAILED";
export const PRODUCT_FILTERS_SUCCESS = "PRODUCT_FILTERS_SUCCESS";

/****** Brands List */
export const BRANDS_LIST_SUCCESS = "BRANDS_LIST_SUCCESS";



/*** Category product  */
export const CAT_PRODUCT_LIST = "CAT_PRODUCT_LIST";
export const CAT_PRODUCT_LIST_FAILED = "CAT_PRODUCT_LIST_FAILED";
export const CAT_PRODUCT_LIST_SUCCESS = "CAT_PRODUCT_LIST_SUCCESS";

/***  Product Product Recent Viewed  */
export const PRODUCT_RECENT_VIEWED_ADD = "PRODUCT_RECENT_VIEWED_ADD";
export const PRODUCT_RECENT_VIEWED_LIST = "PRODUCT_RECENT_VIEWED_LIST";

