import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { storageUrl } from "../../common";

const Logo = ({ imageUrl, logoClass }) => {
  return (
    <div className={`${logoClass ? logoClass : ""}`}>
      <Link
        to={process.env.PUBLIC_URL + "/"}
        refresh="true"
        onClick={() => (window.location.href = process.env.PUBLIC_URL)}
      >
        <img
          style={{
            width: "155px",
            /* height: 24px; */
            height: "45px",
          }}
          alt=""
          src={
            storageUrl+"configuration/o0slcTdG09Uz5NMwLIpqXaYlp7p1wuEx9mazu4cb.png"
          }
        />
        {/* <img alt="" src="\assets\img\logo\logo2.png" /> */}
      </Link>
    </div>
  );
};

Logo.propTypes = {
  imageUrl: PropTypes.string,
  logoClass: PropTypes.string,
};

export default Logo;
