import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";

import HeroSlider from "../../components/custom/HeroSlider";
import AboutSection from "../../components/custom/AboutSection";
import TabProduct from "../../components/custom/TabProduct";
import NewLaunches from "../../components/custom/NewLaunches";
import HeroSliderTwo from "../../wrappers/hero-slider/HeroSliderTwo";
import {getnewproduct,getBestSellerProduct,getSaleItemProduct} from "../../redux/product/productActions"

import { getBrands } from "../../redux/product"

import { Link } from "react-router-dom";

import ErrorHandler from "../../components/commoncomponent/ErroHandler";

const CustomHomePage = ({getBrands,category,getnewproduct,getBestSellerProduct,getSaleItemProduct }) => {

  useEffect( () => {
    getnewproduct({ limit: 10, category: 1, attribute: "&featured=1" });
  
  }, [] );

  
  return (
    <Fragment>
      <MetaTags>
        <title>Gang Bicycle</title>
        <meta name="description" content="Gang Bicycle" />
      </MetaTags>
      <LayoutOne
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-1"
      >
        {/* <HeroSliderTwo /> */}
        <HeroSlider />
        <AboutSection />
        <TabProduct />
        <NewLaunches />
      </LayoutOne>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    category:state.category,  
    product:state.productData
  }
}  

const mapDispatchToProps = {
  getBrands:getBrands,
  getnewproduct: getnewproduct,
  getBestSellerProduct: getBestSellerProduct,
  getSaleItemProduct:getSaleItemProduct
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomHomePage);
