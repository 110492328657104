import React from "react";
const AboutSection = ({}) => {
  return (
    <div class="about-area pt-60 bg-shaded-light pb-60">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-text-center">
            <div class="section-title text-center">
              <h3>
                <span>About</span>{" "}
                <span class="brand">
                  <img
                    src="http://gangcycles.com/gangadmin/public/storage/configuration/about1.png"
                    alt=""
                    style={{ marginTop: "-4px" }}
                  />
                </span>
              </h3>
              <div class="shape">
                <lord-icon
                  src="https://cdn.lordicon.com/mlnkdrif.json"
                  trigger="loop"
                  colors="primary:#707070,secondary:#000000"
                  style={{ width: "50px", height: "50px" }}
                ></lord-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div
              class="about-text text-center"
              style={{
                color: "red",
                width: "90%",
                margin: "auto",
                lineHeight: "35px !important",
              }}
            >
              <p>
                <span class="gang-brand">
                  <img
                    src="http://gangcycles.com/gangadmin/public/storage/configuration/about2.png"
                    alt=""
                    style={{ marginTop: "-2px" }}
                  />{" "}
                </span>{" "}
                is a Flagship Brand of Bicycles from SK Bikes with a motive to
                enhance your cycling experience by providing you optimum comfort
                during cycling and value for money.{" "}
                <span class="gang-brand">
                  <img
                    src="http://gangcycles.com/gangadmin/public/storage/configuration/about2.png"
                    alt=""
                    style={{ marginTop: "-2px" }}
                  />
                </span>{" "}
                as a Brand is Young, Passionate and Energetic and so is our
                Product Range. Our focus has remained to be a leading name for
                Fancy &amp; Designer Bicycles with Attractive Colours &amp; Eye
                Catching Graphics. We have been successful in Achieving this
                which has brought us recognition in the Indian Bicycle Market.
                Our Product Range offers Bicycles for people of all age segments
                including Kids. We ensure you that being a member of our{" "}
                <span class="gang-brand">
                  <img
                    src="http://gangcycles.com/gangadmin/public/storage/configuration/about2.png"
                    alt=""
                    style={{ marginTop: "-2px" }}
                  />
                </span>{" "}
                would be the perfect way to enjoy Cycling.{" "}
              </p>
            </div>
            <div class="read-more text-center" style={{ marginTop: "30px" }}>
              <a
                class="read-more-btn text-uppercase"
                href={process.env.PUBLIC_URL + "/page/about-us"}
              >
                <i class="fa fa-bicycle"></i>Read More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
