import React from 'react';
import { List,Instagram,BulletList } from 'react-content-loader'
import Catalog from './CatalogLoader'
import ProductDetails from './ProductDetailsLoader'

export const CatalogLoader = () => <Catalog />
export const ProductDetailsLoader = () => <ProductDetails />

export const ProductListLoader = () => <List />
export const ProductDetailLoader = () => <Instagram />
export const ProductAdditionalListLoader = () => <BulletList />