import React, { Component, useEffect } from "react";
import { Fragment } from "react";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import { connect } from "react-redux";
import { getbanner } from "../../redux/banners";
import { frontUrl, storageUrl } from "../../common";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={"next slick-arrow fa fa-angle-right"}
      style={{
        ...style,
        borderRadius: "25px",
        padding: "12px 20px",
        backgroundColor: "#fff",
        fontSize: "22px",
        boxShadow: "rgba(0,0,0,.24) 0px 3px 8px",
      }}
      onClick={onClick}
    />
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={"prev slick-arrow fa fa-angle-left"}
      style={{
        ...style,
        borderRadius: "25px",
        padding: "12px 20px",
        backgroundColor: "#fff",
        fontSize: "22px",
        boxShadow: "rgba(0,0,0,.24) 0px 3px 8px",
      }}
      onClick={onClick}
    />
  );
}
const SimpleSlider = ({ getbanner, banners, dispatch }) => {
  useEffect(() => {
    // getbanner();
    getbanner();
  }, []);
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    speed: 1000,
    fade: true,
    pauseOnHover: false,
    cssEase: "ease-in-out",
    cssEase: "cubic-bezier(0.7, 0, 0.3, 1)",
    touchThreshold: 100,
    autoplay: true,
    autoplaySpeed: 4000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Fragment>
      <div class="s-main-slider" style={{ height: "93vh", overflow: "hidden" }}>
        <div class="main-slider" style={{ height: "100%" }}>
          <Slider {...settings}>
            {banners &&
              banners.items.map((single, key) => {
                return (
                  <div class="main-slide" style={{ height: "93vh" }}>
                    <div
                      class="main-slide-bg"
                      style={{
                        backgroundImage: `url("${storageUrl}/${single.path}")`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: "93vh",
                      }}
                    ></div>
                    <div class="slide-img-cover">
                      <img
                        src={storageUrl + "/" + single.imagecover}
                        alt="img"
                        class="slide-img"
                      />
                    </div>
                  </div>
                );
              })}
          </Slider>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    banners: state.banners,
  };
};

// const mapDispatchToProps = dispatch => {
//   return {
//     getbanner: () => {
//       dispatch(getbanner());
//     },
//   }
// }

const mapDispatchToProps = {
  getbanner,
};  

export default connect(mapStateToProps, mapDispatchToProps)(SimpleSlider);
