// import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import { connect } from "react-redux";
import Nav from "react-bootstrap/Nav";
import CustomTabContent from "./CustomTabContent";

import { getcatproduct } from "../../redux/product";
const TabProduct = ({ category, product, getcatproduct }) => {
  useEffect(() => {
    getcatproduct({ limit: 6, category: 4, attribute: "" });
  }, []);


  const tabclick = (event) => {
    //console.log("tab click",event)
getcatproduct({ limit: 10, category: event, attribute: "" });


  }
  return (
    <div className={`product-area pt-60 bg-shaded pb-60`}>
      <div className="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-text-center">
            <div class="section-title text-center">
              <h3
                className="uppercase"
                style={{ color: "#1b1b1b", fontWeight: "600" }}
              >
                <span>OUR</span> Range
              </h3>
              <div class="shape">
                <lord-icon
                  src="https://cdn.lordicon.com/mlnkdrif.json"
                  trigger="loop"
                  colors="primary:#707070,secondary:#000000"
                  style={{ width: "50px", height: "50px" }}
                ></lord-icon>
              </div>
            </div>
          </div>
        </div>

        <Tab.Container defaultActiveKey="4">
          <Nav
            variant="pills"
            onSelect={tabclick}
            className={`product-tab-list`}
          >
            {category.items.map((values, index) => (
              <Nav.Item>
                <Nav.Link eventKey={values.id}>
                  <h4>{values.name}</h4>
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>

          <Tab.Content>
            {category.items.map((values, index) => (
              <Tab.Pane eventKey={values.id}>
                <div className="row">
                  <CustomTabContent products={product} cat={values} />  
                </div>
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    product: state.catproduct,
    category:state.category
    
  }
}


const mapDispatchToProps = {
  getcatproduct: getcatproduct
};

export default connect(mapStateToProps, mapDispatchToProps)(TabProduct);


