import React, { Component } from "react";
import { Fragment } from "react";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return (
	  <div
		className={"next slick-arrow fa fa-angle-right"}
		style={{ ...style ,borderRadius: "25px", padding: "12px 20px", backgroundColor:"#fff", fontSize: "22px", boxShadow: "rgba(0,0,0,.24) 0px 3px 8px" }}
		onClick={onClick}
	  />
	);
  }
  
  function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return (
	  <div
		className={"prev slick-arrow fa fa-angle-left"}
		style={{ ...style ,borderRadius: "25px", padding: "12px 20px", backgroundColor:"#fff", fontSize: "22px", boxShadow: "rgba(0,0,0,.24) 0px 3px 8px" }}
		onClick={onClick}
	  />
	);
  }

const NewLaunches = ({ productlist})=>{
	
		const settings = {
			centerMode: true,
			centerPadding: '0px',
			slidesToShow: 3,
			nextArrow: <SampleNextArrow />,
      		prevArrow: <SamplePrevArrow />,
			responsive: [{
				breakpoint: 1169,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 969,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1
				}
			}
		]
		};
		return (
      <Fragment>
        <div class="featured-area featured-one pt-60 pb-60">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-text-center">
                <div class="section-title text-center">
                  <h3
                    className="uppercase"
                    style={{ color: "#1b1b1b", fontWeight: "600" }}
                  >
                    <span>New</span> Launches
                  </h3>
                  <div class="shape">
                    <lord-icon
                      src="https://cdn.lordicon.com/mlnkdrif.json"
                      trigger="loop"
                      colors="primary:#707070,secondary:#000000"
                      style={{ width: "50px", height: "50px" }}
                    ></lord-icon>
                  </div>
                </div>
              </div>
            </div>
            <div class="featured-slider single-products text-center">
              <Slider {...settings}>
                {productlist.items.length > 0 ? (
                  productlist.items.map((product) => {
                  return (
                    <div class="single-slide">
                      <div class="padding30">
                        <div class="product-item">
                          <div class="pro-img">
                            <Link
                              to={
                                process.env.PUBLIC_URL +
                                "/product/" +
                                product.url_key
                              }
                              onClick={(event) =>
                                localStorage.setItem(
                                  "pro" + product.url_key,
                                  product.name
                                )
                              }
                            >
                              <img
                                src={
                                  product.images != undefined &&
                                  product.images.length > 0
                                    ? product.images[0].large_image_url
                                    : ""
                                }
                                alt=""
                              />
                            </Link>
                          </div>
                          <div class="actions-btn">
                            <ul class="clearfix">
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/product/" +
                                    product.url_key
                                  }
                                  onClick={(event) =>
                                    localStorage.setItem(
                                      "pro" + product.url_key,
                                      product.name
                                    )
                                  }
                                >
                                  {product.name}
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div class="product-title">
                            <Link
                              to={
                                process.env.PUBLIC_URL +
                                "/product/" +
                                product.url_key
                              }
                              onClick={(event) =>
                                localStorage.setItem(
                                  "pro" + product.url_key,
                                  product.name
                                )
                              }
                            >
                              <h5
                                dangerouslySetInnerHTML={{
                                  __html: product.short_description.replace(
                                    /<[^>]+>/g,
                                    ""
                                  ),
                                }}
                              ></h5>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                  })
                ) : (
                  <div className="no_record_com"> No Product available!</div>
                )}  
              </Slider>
            </div>
            <div class="check-coll">
              <Link
                to={process.env.PUBLIC_URL + "/catalog/root?featured=1" }
                className="check-coll-btn text-uppercase"
              >
                <i class="fa fa-bicycle"></i>Check All New Launches
              </Link>
            </div>
          </div>
        </div>
      </Fragment>
    );
	
}

const mapStateToProps = (state, ownProps) => {
  return {
    productlist: state.product,
  };
};

export default connect(mapStateToProps)(NewLaunches);
