export const ApiEndpointUrl = "https://gangcycles.com/gangadmin/public/api/";
export const storageUrl = "https://gangcycles.com/gangadmin/public/storage/";

// export const ApiEndpointUrl = "https://emsclub.in/public/index.php/api/";
// export const storageUrl = "https://emsclub.in/public/storage/";

export const clientLogoUrl = "https://gangcycles.com/gangadmin/public/cache/large/";
export const frontUrl = "https://gangcycles.com/gangadmin/public/front";  
export const Logintoken = ()=>{     
      
    if(localStorage.getItem('auth') !=undefined)
    {
        return JSON.parse(localStorage.getItem('auth')).token
    }
    return '';

}
export default {};
