import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const FooterCopyright = ({ footerLogo, spaceBottomClass, colorClass }) => {
  return (
    <div
      className={`copyright ${spaceBottomClass ? spaceBottomClass : ""} ${
        colorClass ? colorClass : ""
      }`}
    >
      <div className="footer-logo">
        <Link
          to={process.env.PUBLIC_URL + "/"}
          refresh="true"
          onClick={() => (window.location.href = process.env.PUBLIC_URL)}
        >
          <img
            alt=""
            src={
              "http://gangcycles.com/gangadmin/public/storage/configuration/4VGoYXGoJcl5x8zb0ynzr3oIyjDStcqoPrKcPgdG.png"
            }
          />
        </Link>
      </div>
      <p>
        © 2020{" "}
        <a href="//hasthemes.com" rel="noopener noreferrer" target="_blank">
          Devoloped by CYBERFRAME
        </a>
        .<br /> All Rights Reserved
      </p>
    </div>
  );
};

FooterCopyright.propTypes = {
  footerLogo: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string,
};

export default FooterCopyright;
